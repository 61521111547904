import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

//保存原型对象的Push
let originPush = VueRouter.prototype.push
let originReplace = VueRouter.prototype.replace
//重写push方法
VueRouter.prototype.push = function (location, res, rej) {
  if (res && rej) {
    originPush.call(this, location, res, rej)
  }
  else {
    originPush.call(this, location, () => { }, () => { })
  }

}
//重写replace方法
VueRouter.prototype.replace = function (location, res, rej) {
  if (res && rej) {
    originReplace.call(this, location, res, rej)
  }
  else {
    originReplace.call(this, location, () => { }, () => { })
  }
}

const routes = [
  {
    path: '/',
    redirect: '/homePage'  // 将根路径重定向到 '/homePage'
  },
  {
    path: '/login',
    name: 'login',
    meta: { title: '登录' },
    component: () => import('../views/login.vue')
  },
  {
    path: '/scanCode',
    name: 'scanCode',
    meta: { title: '扫一扫' },
    component: () => import('../views/scanCode.vue'),
    meta: {  // 加一个自定义obj
      requireAuth: true   // 参数 true 代表需要登陆才能进入 A
    }
  },
  {
    path: '/text',
    name: 'text',
    meta: { title: '扫一扫' },
    component: () => import('../views/text.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/homePage',
    name: 'homePage',
    meta: { title: '首页' },
    component: () => import('../views/homePage/index.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/bindUse',
    name: 'bindUse',
    meta: { title: '绑定用水' },
    component: () => import('../views/homePage/components/bindUse.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/bingWaterVales',
    name: 'bingWaterVales',
    meta: { title: '云热水' },
    component: () => import('../views/homePage/components/bingWaterVales.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/bindCodeMeter',
    name: 'bindCodeMeter',
    meta: { title: '扫码绑定' },
    component: () => import('../views/homePage/components/bindCodeMeter.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/bindScanCode',
    name: 'bindScanCode',
    meta: { title: '扫一扫' },
    component: () => import('../views/homePage/components/bindScanCode.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/bindRoomMeter',
    name: 'bindRoomMeter',
    meta: { title: '房间绑定' },
    component: () => import('../views/homePage/components/bindRoomMeter.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/shareBlowDryer',
    name: 'shareBlowDryer',
    meta: { title: '共享吹风机' },
    component: () => import('../views/homePage/components/shareBlowDryer.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/shareWashPay',
    name: 'shareWashPay',
    meta: { title: '支付页面' },
    component: () => import('../views/homePage/components/shareWashPay.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/shareWashing',
    name: 'shareWashing',
    meta: { title: '共享洗衣机' },
    component: () => import('../views/homePage/components/shareWashing.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/recharge',
    name: 'recharge',
    meta: { title: '充值' },
    component: () => import('../views/person/components/recharge.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/useRecords',
    name: 'useRecords',
    meta: { title: '使用记录' },
    component: () => import('../views/person/components/useRecords.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/myBindMeter',
    name: 'myBindMeter',
    meta: { title: '绑定水表' },
    component: () => import('../views/person/components/myBindMeter.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/modifyData',
    name: 'modifyData',
    meta: { title: '修改资料' },
    component: () => import('../views/person/components/modifyData.vue'),
    meta: {
      requireAuth: false
    }
  },
  {
    path: '/repair',
    name: 'repair',
    meta: { title: '设备报修' },
    component: () => import('../views/person/components/repair.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/repairRecords',
    name: 'repairRecords',
    meta: { title: '报修记录' },
    component: () => import('../views/person/components/repairRecords.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/person',
    name: 'person',
    meta: { title: '我的' },
    component: () => import('../views/person/index.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/helpLi',
    name: 'helpLi',
    meta: { title: '帮助中心' },
    component: () => import('../views/useHelp/helpLi.vue'),
    meta: {
      requireAuth: true
    }
  },
  // 安装水表扫一扫
  {
    path: '/installMeter',
    name: 'installMeter',
    meta: { title: '扫一扫' },
    component: () => import('../views/person/components/installMeter.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/bindroominfo',
    name: 'bindroominfo',
    meta: { title: '安装水表' },
    component: () => import('../views/person/components/bindroominfo.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/helpOne',
    name: 'helpOne',
    meta: { title: '4G物联网智能设备' },
    component: () => import('../views/useHelp/helpOne.vue'),
    meta: {
      requireAuth: true
    }
  },
]

const router = new VueRouter({
  routes
})
import { Dialog } from 'vant';
router.beforeEach((to, from, next) => {
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  const token = localStorage.getItem('token')
  if (to.meta.requireAuth) {
    if (!token) {
      console.log("没有token信息，应该跳到登录")
      next({ path: '/login' })
    } else {
      console.log("有token信息，留在原页面")
      next()
      // const school = userInfo.school
      // if (!school || school == null || school == '') {
      //   console.log("没有学校，应该修改个人资料绑定信息")
      //   Dialog.confirm({
      //     title: '提示',
      //     message: '您需要先完善个人资料才能继续访问该页面。是否立即跳转到个人资料绑定页面？'
      //   }).then(() => {
      //     // 用户点击确定
      //     next({ path: '/modifyData' });
      //   }).catch(() => {
      //     // 用户点击取消
      //     next(false);  // 取消路由跳转
      //   });
      // } else {
      //   console.log("有学校，应该留在当前页面")
      //   next()
      // }
    }
  } else {
    next()
  }
})

export default router
